//src/api/brandapi.js
import request from '@/utils/request'

/**
 * POST 方法 与 GET方法  区别
 *
 * post==> data: obj
 * get==>params: obj
 *
 */
/*广告接口---------------------------*/
//获取广告列表接口
export function getdeductionData(obj) {
    return request({
        url: '/api/admin/deduction',      // url = base url + request url
        method: 'get',
        params: obj,
        //----------------->>>区别
    })
}
//新增广告接口
export function postadddeduction(obj,url) {
    return request({
        url: '/api/admin/deduction/add',      // url = base url + request url
        method: 'post',
        data: obj,
        //----------------->>>区别
    })
}
//编辑广告接口
export function posteditdeduction(obj,url) {
    return request({
        url: '/api/admin/deduction/edit',      // url = base url + request url
        method: 'post',
        data: obj,
        //----------------->>>区别
    })
}
//删除广告接口
export function postdeldeduction(obj,url) {
    return request({
        url: '/api/admin/deduction/del',      // url = base url + request url
        method: 'post',
        data: obj,
        //----------------->>>区别
    })
}

//获取广告列表接口
export function getgetInfoData(obj) {
    return request({
        url: '/api/admin/deduction/getInfo',      // url = base url + request url
        method: 'get',
        params: obj,
        //----------------->>>区别
    })
}
//删除广告接口
export function postdelArea(obj,url) {
    return request({
        url: '/api/admin/deduction/delArea',      // url = base url + request url
        method: 'post',
        data: obj,
        //----------------->>>区别
    })
}
//删除广告接口
export function postdelWorker(obj,url) {
    return request({
        url: '/api/admin/deduction/delWorker',      // url = base url + request url
        method: 'post',
        data: obj,
        //----------------->>>区别
    })
}


/*扣费编码接口---------------------------*/
//获取扣费编码列表接口
export function getcodeData(obj) {
    return request({
        url: '/api/admin/deduction-code',      // url = base url + request url
        method: 'get',
        params: obj,
        //----------------->>>区别
    })
}
//新增扣费编码接口
export function postaddcode(obj,url) {
    return request({
        url: '/api/admin/deduction-code/add',      // url = base url + request url
        method: 'post',
        data: obj,
        //----------------->>>区别
    })
}
//编辑扣费编码接口
export function posteditcode(obj,url) {
    return request({
        url: '/api/admin/deduction-code/edit',      // url = base url + request url
        method: 'post',
        data: obj,
        //----------------->>>区别
    })
}
//删除扣费编码接口
export function postdelcode(obj,url) {
    return request({
        url: '/api/admin/deduction-code/del',      // url = base url + request url
        method: 'post',
        data: obj,
        //----------------->>>区别
    })
}

