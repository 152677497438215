<template>
  <div class="about">
    <div>
      <el-input style="width: 300px;margin: 0 10px 0 0"  v-model="keywords"></el-input>
      <el-button @click="search()" type="primary" style="margin:10px 0;" icon="el-icon-search"></el-button>
    </div>
    <el-table  height="calc(100vh - 230px)" :data="tableData" style="width: 100%;">
      <el-table-column  align="center"
                        type="index"
                         width="55" label="#" >
      </el-table-column>
        <el-table-column prop="name" label="名称">
        </el-table-column>
        <el-table-column prop="code" label="是否有效">
          <template #default="scope">
            <el-tag size="mini" v-if="scope.row.enabled==1">有效</el-tag>
            <el-tag size="mini" type="info" v-if="scope.row.enabled==0">无效</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="address" label="操作" width="200">
          <template #header>
            <el-button  size="mini" @click="add()" type="primary" style="margin:10px 0;">新增</el-button>
          </template>
          <template #default="scope">
            <div style="margin: 5px 0">
              <el-button-group>
                <el-button size="mini" type="primary"  @click="seeCou(scope.row)">扣费详情</el-button>
                <el-button size="mini" type="primary" icon="el-icon-edit" @click="upData(scope.row)"></el-button>
                <el-button size="mini" type="primary" icon="el-icon-delete" @click="deleData(scope.row)"></el-button>
              </el-button-group>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div style="margin-top: 20px">
        <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="page"
            :page-sizes="[10, 20, 30, 40,50]"
            :page-size="limit"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total">
        </el-pagination>
      </div>
    <el-drawer
        title="扣费新增/修改"
        v-model="drawer"
        size="50%"
        direction="rtl"
        :close-on-click-modal="false"
        destroy-on-close>
      <el-scrollbar height="calc(100vh - 160px)" style="height:calc(100% - 30px) ">
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm">
        <el-form-item label="规则名称" prop="name">
          <el-input v-model="ruleForm.name"></el-input>
        </el-form-item>
        <el-form-item label="扣费编码" prop="code_id">
          <el-select style="width: 100%" v-model="ruleForm.code_id" placeholder="">
            <el-option
                v-for="item in codeArr"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="状态" prop="enabled">
          <div style="display: flex;justify-content: space-between;align-items:center;height: 40px">
            <el-switch
                style="display: block"
                v-model="ruleForm.enabled"
                active-text="有效"
                inactive-text="无效"
                :active-value="1"
                :inactive-value="0">
            </el-switch>
          </div>
        </el-form-item>
          <el-form-item label="扣费范围" prop="scope">
            <el-select style="width: 100%" @change="scopeFun" v-model="ruleForm.scope" placeholder="">
              <el-option
                  v-for="item in scopeArr"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="扣费标准" prop="standard">
            <el-select style="width: 100%" v-model="ruleForm.standard" placeholder="">
              <el-option
                  v-for="item in standardArr"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="模式" prop="scope">
            <el-select style="width: 100%" v-model="ruleForm.model" placeholder="">
              <el-option
                  v-for="item in modeArr"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
        <el-form-item v-if="ruleForm.standard==0" label="扣费值" prop="value">
          <el-input-number style="width: 100%" type="number" :precision="2" :min="0" v-model="ruleForm.value"></el-input-number>
        </el-form-item>
        <el-form-item v-else label="百分比(%)" prop="value">
          <el-input-number style="width: 100%" type="number" :precision="2" :min="0" v-model="ruleForm.value"></el-input-number>
        </el-form-item>
        <el-form-item v-if="ruleForm.model==1" label="固定时间" prop="start_time">
          <el-date-picker
              v-model="start_time"
              type="daterange"
              value-format="YYYY-MM-DD HH:mm:ss"
              range-separator="至"
              start-placeholder="开始时间"
              end-placeholder="结束时间">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="内容详情" prop="deposit">
          <el-input type="textarea" :rows="2" v-model="ruleForm.user_limit_count"></el-input>
        </el-form-item>
        <div v-if="ruleForm.scope==1">
          <div style="color: #606266;display: flex;justify-content: space-between"><span>● 扣费城市</span><el-button size="medium" type="text" @click="addProduct()"> 添加扣费城市</el-button></div>
          <el-table  max-height="300px" :data="ruleForm.area" style="width: 100%;margin:10px 0 30px 0">
            <el-table-column  type="index" label="#" width="60">
            </el-table-column>
            <el-table-column prop="name" label="城市名称" min-width="110">
            </el-table-column>
            <el-table-column fixed="right" prop="unit" label="操作" width="57">
              <template #default="scope">
                <el-button size="medium" type="text" @click="deletCou(scope.row,scope.$index)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>


        <div v-if="ruleForm.scope==2">
          <div style="color: #606266;display: flex;justify-content: space-between"><span>● 扣费师傅</span><el-button size="medium" type="text" @click="addincome()"> 添加扣费师傅</el-button></div>
          <el-table  max-height="300px" :data="ruleForm.worker" style="width: 100%;margin:10px 0 30px 0">
            <el-table-column  type="index" label="#" width="60">
            </el-table-column>
            <el-table-column prop="name" label="师傅名称" min-width="110">
              <template #default="scope">
                {{scope.row.name}}-{{scope.row.phone}}
              </template>
            </el-table-column>
            <el-table-column fixed="right" prop="unit" label="操作" width="57">
              <template #default="scope">
                <el-button size="medium" type="text" @click="deletincome(scope.row,scope.$index)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-form>
      </el-scrollbar>
      <div style="text-align: right">
        <el-button type="primary" v-if="ruleForm.id" :loading="login" @click="okAdd('ruleForm',2)">修改</el-button>
        <el-button type="primary" v-else :loading="login" @click="okAdd('ruleForm',1)">保存</el-button>
      </div>
      <el-drawer
          title="新增扣费城市"
          size="40%"
          :append-to-body="true"
          v-model="LabeDrawerCou" >
        <el-scrollbar style="height: 90%" height="600px"  >
          <div>
            <el-input size="mini" style="width: 300px;margin: 0 10px 0 0"  v-model="Coukeywords"></el-input>
            <el-button size="mini" @click="Cousearch()" type="primary" style="margin:10px 0;" icon="el-icon-search"></el-button>
          </div>
          <el-table :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"  @select="handChange"  max-height="450px" :data="CoukListarr" default-expand-all row-key="id" style="width: 100%;margin:10px 0 30px 0">
            <el-table-column
                type="selection"
                width="55">
            </el-table-column>
            <el-table-column prop="name" label="地区名称" min-width="120">
            </el-table-column>
          </el-table>
          <el-pagination
              small
              background
              :pager-count="5"
              @size-change="CouSizeChange"
              @current-change="CouCurrentChange"
              :current-page="Coupage"
              :page-sizes="[10, 20, 30, 40,50]"
              :page-size="Coulimit"
              layout="total, sizes, prev, pager, next, jumper"
              :total="Coutotal">
          </el-pagination>
        </el-scrollbar>
        <el-button type="primary" @click="okaddCou()">确定</el-button>
      </el-drawer>
      <el-drawer
          title="新增扣费师傅"
          size="40%"
          :append-to-body="true"
          v-model="LabeDrawerInc" >
        <el-scrollbar style="height: 90%" height="600px"  >
          <div>
            <el-input size="mini" style="width: 300px;margin: 0 10px 0 0"  v-model="Inckeywords"></el-input>
            <el-button size="mini" @click="incsearch()" type="primary" style="margin:10px 0;" icon="el-icon-search"></el-button>
          </div>
          <el-table :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"  @select="incChange"  max-height="450px" :data="InckListarr" default-expand-all row-key="id" style="width: 100%;margin:10px 0 30px 0">
            <el-table-column
                type="selection"
                width="55">
            </el-table-column>
            <el-table-column prop="nickname" label="师傅名称" min-width="120">
              <template #default="scope">
                {{scope.row.nickname}}-{{scope.row.mobile_phone}}
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
              small
              background
              :pager-count="5"
              @size-change="CouSizeChange"
              @current-change="CouCurrentChange"
              :current-page="Incpage"
              :page-sizes="[10, 20, 30, 40,50]"
              :page-size="Inclimit"
              layout="total, sizes, prev, pager, next, jumper"
              :total="Inctotal">
          </el-pagination>
        </el-scrollbar>
        <el-button type="primary" @click="okaddInc()">确定</el-button>
      </el-drawer>

    </el-drawer>
    <el-drawer
        title="扣费详情"
        size="50%"
        :append-to-body="true"
        v-model="CouponListdraw" >
      <el-scrollbar style="height: 90%" height="600px"  >
        <el-descriptions border :title="CouponList.name">
          <el-descriptions-item label="名称:">
            {{CouponList.name}}
           </el-descriptions-item>
          <el-descriptions-item label="模式:">
            <el-tag  v-if="CouponList.model==0">永久</el-tag>
            <el-tag  type="success" effect="dark" v-else-if="CouponList.model==1">固定时间</el-tag>
          </el-descriptions-item>
          <el-descriptions-item label="扣费金额:">
            ￥{{CouponList.value}}
          </el-descriptions-item>
          <el-descriptions-item v-if="CouponList.model==1" label="固定日期:">
            {{CouponList.start_time}}--{{CouponList.closing_time}}
          </el-descriptions-item>
          <el-descriptions-item label="描述:">
            {{CouponList.content}}
          </el-descriptions-item>

<!--          <el-descriptions-item label="是否派单/接单:">-->
<!--            <el-tag type="danger" v-if="CouponList.is_delivery==0">否</el-tag>-->
<!--            <el-tag  type="success" effect="dark" v-else-if="CouponList.is_delivery==1">是</el-tag>-->
<!--          </el-descriptions-item>-->
        </el-descriptions>
      </el-scrollbar>
    </el-drawer>
  </div>
</template>
<script>
// @ is an alias to /src
import * as axios from "@/api/deductionApi"//本页面接口
import {getArea} from "@/api/common"//本页面接口
import {getSkuData,postSkuData} from "@/api/skuApi"//本页面接口
export default {
  name: 'brand',
  data(){
    return{
      CouponList:{},//扣费详情
      login:false,
      drawer:false,
      CouponListdraw:false,//优惠券详情
      tableData:[],
      codeArr:[],
      start_time:[],
      standardArr:[
        {
          id:0,
          name:'固定金额',
        },
        {
          id:1,
          name:'百分比',
        }
      ],
      modeArr:[
        {
          id:0,
          name:'永久',
        },
        {
          id:1,
          name:'固定时间',
        }
      ],
      scopeArr:[
        {
          id:0,
          name:'全平台',
        },
        {
          id:1,
          name:'城市',
        },
        {
          id:2,
          name:'指定师傅',
        }
      ],
      /*商品--*/
      CoukListarr:[],//城市组
      CoukList:[],//城市
      nowCoukList:[],//选中城市
      LabeDrawerCou:false,//标签
      /*商品--*/

      /*师傅--*/
      InckListarr:[],//规则组
      InckList:[],//师傅
      nowInckList:[],//选中规则
      LabeDrawerInc:false,//师傅
      Incpage:1,
      Inclimit:10,
      Inctotal:0,
      Inckeywords:'',
      /*师傅--*/


      page:1,
      limit:10,
      total:0,
      keywords:'',
      Coupage:1,
      Coulimit:10,
      Coutotal:0,
      Coukeywords:'',
      ruleForm:{
        name:'',
        code_id:'',
        enabled:1,
        scope:0,
        standard:0,
        model:0,
        value:0,
        start_time:'',
        closing_time:'',
        content:'',
        area:[],
        worker:[],
      },
      rules:{
        name: [
          { required: true, message: '请填写名称', trigger: 'change' },
        ],
        value: [
          { required: true, message: '请填写名称', trigger: 'change' },
        ],
        code_id: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        enabled: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        scope: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        standard: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        model: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
      }
    }
  },
  created() {
    this.onTable()
    this.keyupSubmit()
    this.oncodeArr()
  },
  methods:{
    keyupSubmit(){
      document.onkeydown=e=>{
        let _key=window.event.keyCode;
        if(_key===13){
          this.search()
        }
      }
    },
    scopeFun(){
        this.ruleForm.area=[]
        this.ruleForm.worker=[]
    },
    seeCou(row){
      axios.getgetInfoData({id:row.id}).then((res)=>{
        if(res.code==200){
          this.CouponList=res.data
          this.CouponListdraw=true
        }else {

        }
      }).catch(()=>{

      })

    },
    oncodeArr(row){
      let data={
        page:1,
        limit:1000
      }
      axios.getcodeData(data).then((res)=>{
        if(res.code==200){
          this.codeArr=res.data.data
        }else {

        }
      }).catch(()=>{

      })

    },

    /*指定规则*/
    incsearch(){
      this.Incpage=1
      this.onIncomdata()
    },
    onIncomdata(){
      let data={
        page:this.Incpage,
        limit:this.Inclimit,
        keywords:this.Inckeywords,
        w_type:'all',
      }
      getSkuData(data,'/api/admin/worker').then((res)=>{
        console.log(res)
        this.InckListarr=res.data.data
      }).catch((err)=>{

      })
    },
    addincome(){
      this.onIncomdata()
      this.LabeDrawerInc=true
    },
    InsSizeChange(val) {
      console.log(`每页 ${val} 条`)
      this.Inclimit=val
      this.onIncomdata()
    },
    InsCurrentChange(val) {
      console.log(`当前页: ${val}`)
      this.Incpage=val
      this.onIncomdata()
    },
    incChange(selection,row){
      var newNum1 = this.ruleForm.worker.find((item, index) => {
        return item.worker_id == row.id;
      })
      if(newNum1){
        console.log(newNum1)
      }else {
        this.ruleForm.worker.push({worker_id:row.id,name:row.nickname,phone:row.mobile_phone})
      }
    },
    okaddInc(){
      this.LabeDrawerInc=false
    },
    deletincome(row,i){
      if(row.id){
        this.$confirm('删除!!, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          postSkuData({id:row.id},'/api/admin/deduction/delWorker').then((res)=>{
            if(res.code==200){
              this.$message.success(res.msg)
              this.ruleForm.worker.splice(i,1)
            }else {
              this.$message.error(res.msg)
            }
          }).catch((err)=>{
            this.$message.error('服务器报错！！请联系管理员')
          })
        })
            .catch(() => {
              this.$message({
                type: 'error',
                message: '已取消删除',
              })
            })
      }else {
        this.ruleForm.worker.splice(i,1)
      }
    },
    /*-----------*/

    /*指定城市*/
    Cousearch(){
      this.Coupage=1
      this.onProductdata()
    },
    onProductdata(){
      let data={
        page:this.Coupage,
        limit:this.Coulimit,
        keywords:this.Coukeywords,
      }
      getArea(data).then((res)=>{
        if(res.code==200){
          this.CoukListarr=res.data
        }
      }).catch(()=>{

      })
    },
    addProduct(){
      this.onProductdata()
      this.LabeDrawerCou=true
    },
    CouSizeChange(val) {
      console.log(`每页 ${val} 条`)
      this.Coulimit=val
      this.onProductdata()
    },
    CouCurrentChange(val) {
      console.log(`当前页: ${val}`)
      this.Coupage=val
      this.onProductdata()
    },
    handChange(selection,row){
      var newNum1 = this.ruleForm.area.find((item, index) => {
        return item.area_id == row.id;
      })
      if(newNum1){
        console.log(newNum1)
      }else {
        this.ruleForm.area.push({area_id:row.id,name:row.name})
      }
    },
    okaddCou(){
      this.LabeDrawerCou=false
    },
    deletCou(row,i){
      if(row.id){
        this.$confirm('删除!!, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
              axios.postdeldeduction({id:row.id}).then((res)=>{
                if(res.code==200){
                  this.$message.success(res.msg)
                  this.ruleForm.area.splice(i,1)
                }else {
                  this.$message.error(res.msg)
                }
              }).catch((err)=>{
                this.$message.error('服务器报错！！请联系管理员')
              })
            })
            .catch(() => {
              this.$message({
                type: 'error',
                message: '已取消删除',
              })
            })
      }else {
        this.ruleForm.area.splice(i,1)
      }
    },
    /*-----------*/
    onTable(){
      let form={
        page:this.page,
        limit:this.limit,
        keywords:this.keywords,
      }
        axios.getdeductionData(form).then((res)=>{
          console.log(res)
          this.tableData=res.data.data
          console.log('this.tableData',this.tableData)
          this.total=res.data.total
        }).catch((err)=>{

        })
    },
    search(){
      this.page=1
      this.onTable()
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`)
      this.limit=val
      this.onTable()
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`)
      this.page=val
      this.onTable()
    },
    add(){
      this.ruleForm={
        name:'',
        code_id:'',
        enabled:1,
        scope:0,
        standard:0,
        model:0,
        value:0,
        start_time:'',
        closing_time:'',
        content:'',
        area:[],
        worker:[],
      }
      this.start_time=[]
      this.drawer=true
    },
    upData(row){
      axios.getgetInfoData({id:row.id}).then((res)=>{
        if(res.code==200){
          this.start_time=[]
          this.ruleForm.id=res.data.id
          this.ruleForm.name=res.data.name
          this.ruleForm.code_id=res.data.code_id
          this.ruleForm.enabled=res.data.enabled
          this.ruleForm.scope=res.data.scope
          this.ruleForm.standard=res.data.standard
          this.ruleForm.model=res.data.model
          this.ruleForm.value=Number(res.data.value)
          this.ruleForm.content=res.data.content
          this.start_time.push(res.data.start_time)
          this.start_time.push(res.data.closing_time)
          if(res.data.area==''||!res.data.area){
            this.ruleForm.area=[]
          }else {
            this.ruleForm.area=res.data.area
          }
          if(res.data.worker==''||!res.data.worker){
            this.ruleForm.worker=[]
          }else {
            this.ruleForm.worker=res.data.worker
          }
          this.drawer=true
        }else {
          this.$message.error(res.msg)
        }
      }).catch(()=>{
        this.$message.error('服务器报错！！请联系管理员')
      })
    },
    okAdd(formName,val){
      if(this.login){
        return false
      }
      this.login=true
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if(val==1){
            axios.postadddeduction(this.ruleForm).then((res)=>{
                if(res.code==200){
                  this.$message.success(res.msg)
                  this.onTable()
                  let that=this
                  setTimeout(function () {
                    that.drawer=false
                    that.login=false
                  },1000)
                }else {
                  this.login=false
                  this.$message.error(res.msg)
                }
            }).catch((err)=>{
              this.login=false
              this.$message.error('服务器报错！！请联系管理员')
            })
          }else {
            axios.posteditdeduction(this.ruleForm).then((res)=>{
              if(res.code==200){
                this.$message.success(res.msg)
                this.onTable()
                let that=this
                this.login=false
                setTimeout(function () {
                  that.drawer=false
                },1000)
              }else {
                this.login=false
                this.$message.error(res.msg)
              }
            }).catch((err)=>{
              this.login=false
              this.$message.error('服务器报错！！请联系管理员')
            })
          }


        } else {
          this.login=false
          console.log('error submit!!')
          return false
        }
      })
    },
    deleData(row){
      this.$confirm('删除!!, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
          .then(() => {
            axios.postdeldeduction({id:row.id}).then((res)=>{
              if(res.code==200){
                this.$message.success(res.msg)
                this.onTable()
                let that=this
                setTimeout(function () {
                  that.drawer=false
                },1000)
              }else{
                this.$message.error(res.msg)
              }
            }).catch((err)=>{
              this.$message.error('服务器报错！！请联系管理员')
            })
          })
          .catch(() => {
            this.$message({
              type: 'error',
              message: '已取消删除',
            })
          })
    },

  }
}
</script>
<style lang="less" >
.avatar-uploader .el-upload {
  border: 1px dashed #c1c1c1;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.el-upload{

}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.el-table .el-table__cell{
    padding: 0;
}
</style>